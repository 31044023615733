import React from "react";
import ShadowCard from "./shadow-card";
import SubTitle from "./subtitle";
import styled from "styled-components";
import { SP_WIDTH } from "../config/Config";
import LineLink from "./line-link";
const image = require("../images/photos/003.jpg");

const Div = styled.div`
  margin: 0 24px;
  display: flex;
  @media screen and (max-width: ${SP_WIDTH}px) {
    display: block;
  }
  table td {
    border: 0;
  }
  address {
    font-style: inherit;
    flex-grow: 1;
  }

  .image-container {
    max-width: 50%;
    @media screen and (max-width: ${SP_WIDTH}px) {
      max-width: 100%;
    }
  }
`;

const BasicInformation = () => (
  <ShadowCard>
    <SubTitle>基本情報</SubTitle>
    <Div>
      <address>
        <table>
          <tbody>
            <tr>
              <td style={{ width: 94 }}>院名</td>
              <td>
                医療法人 博正会
                <br />
                大渕耳鼻咽喉科クリニック
              </td>
            </tr>
            <tr>
              <td style={{ width: 94 }}>電話</td>
              <td>
                <a href="tel:0943-23-2811">0943-23-2811</a>
              </td>
            </tr>
            <tr>
              <td style={{ width: 94 }}>住所</td>
              <td>
                <a href="https://goo.gl/maps/DzNzVffw8zJe7X9v7" target="_blank">
                  〒834-0063
                  <br />
                  福岡県八女市本村691-1
                </a>
              </td>
            </tr>
            <tr>
              <td style={{ width: 94 }}>診療対象</td>
              <td>鼻・耳・喉の病気など</td>
            </tr>
            <tr>
              <td style={{ width: 94 }}>LINE@</td>
              <td>
                <LineLink />
              </td>
            </tr>
          </tbody>
        </table>
      </address>
      <div className="image-container">
        <img src={image} alt="外観" />
      </div>
    </Div>
  </ShadowCard>
);

export default BasicInformation;
