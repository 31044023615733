import React from "react";

const ReservationBanner = () => {
  return (
    <div
      style={{
        textAlign: "center",
        marginTop: 16,
      }}
    >
      <p>Webから順番予約ができます。(時間帯指定はできません。)</p>
      <a
        href="https://ssc6.doctorqube.com/obuchi-jibika/"
        target="_blank"
        style={{ display: "block", marginBottom: 8 }}
      >
        <img
          src={require("../images/yoyaku500_50b.png")}
          alt="ドクターキューブ予約システム"
        />
      </a>
      <div style={{ display: "inline-block" }}>
        <div style={{ display: "flex", maxWidth: 500, alignItems: "center" }}>
          <img
            src={require("../images/qr.png")}
            alt="ドクターキューブ予約システムQRコード"
          />
          <div style={{ textAlign: "left", padding: 8 }}>
            <h3>WEB予約受付サイト QRコード</h3>
            <figcaption style={{ fontSize: 16, marginTop: 8 }}>
              QRコード読込対応の携帯電話等で、左記のQRコードを撮影すると簡単に予約受付サイトにアクセスできます。
            </figcaption>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReservationBanner;
