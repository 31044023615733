import React from "react";
import styled from "styled-components";
import ShadowCard from "./shadow-card";
import SubTitle from "./subtitle";
import ReservationBanner from "./reservation-banner";
import TimeTable from "./time-table";
import TimeTableUntil20230602 from "./time-table-until-20230602";
import { ClosedDaysLabel } from "./closed-days-label";
import PayPay from "./paypay";

const Wrapper = styled.div`
  padding-bottom: 24px;
`;

const TimeTableWrapper = () => {
  const d = new Date();
  const is20230623TimeTable =
    d.getFullYear() >= 2023 && d.getMonth() >= 5 && d.getDate() >= 3;
  return (
    <Wrapper>
      <ShadowCard>
        <SubTitle>診療時間</SubTitle>
        {is20230623TimeTable ? (
          <>
            <TimeTable />
            <ClosedDaysLabel />
          </>
        ) : (
          <>
            <TimeTableUntil20230602 />
            <ClosedDaysLabel />
          </>
        )}
        <SubTitle>Webによる順番予約サービス</SubTitle>
        <ReservationBanner />
        <div style={{ paddingTop: 24 }}>
          <PayPay />
        </div>
      </ShadowCard>
    </Wrapper>
  );
};

export default TimeTableWrapper;
