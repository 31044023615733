import React from "react";
import styled from "styled-components";

const Table = styled.table`
  margin: 0 0 16px 0;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #dadada;
  tr {
    td,
    th {
      text-align: center;
      border: none;

      @media screen and (max-width: 800px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    &:first-child td {
      border-top: solid 1px #dadada;
    }
    &:not(:first-child) td {
      border-top: dashed 1px #dadada;
    }
    td:not(:last-child),
    th:not(:last-child) {
      border-right: dashed 1px #dadada;
    }
  }
  th {
    background: #ffa41c;
    color: #fff;
  }

  th.first-column {
    width: 30%;
  }
  th.business-day {
    width: 10%;
  }

  .circle {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 14px;
    background-color: #ffa41c;
  }
`;

export default () => {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th className="first-column">診療時間</th>
            <th className="business-day">月</th>
            <th className="business-day">火</th>
            <th className="business-day">水</th>
            <th className="business-day">木</th>
            <th className="business-day">金</th>
            <th className="business-day">土</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>8:30~12:30</td>
            <td>
              <span className="circle" />
            </td>
            <td>
              <span className="circle" />
            </td>
            <td>
              <span className="circle" />
            </td>
            <td>／</td>
            <td>
              <span className="circle" />
            </td>
            <td>
              <span className="circle" />
            </td>
          </tr>
          <tr>
            <td>15:00~18:00</td>
            <td>
              <span className="circle" />
            </td>
            <td>
              <span className="circle" />
            </td>
            <td>
              <span className="circle" />
            </td>
            <td>／</td>
            <td>
              <span className="circle" />
            </td>
            <td>
              13:30~
              <br />
              <span style={{ marginRight: 8 }}>15:00</span>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};
