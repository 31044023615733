import React from "react";

const PayPay = () => {
  return (
    <div
      style={{
        textAlign: "center"
      }}
    >
      <p>当院ではPayPayでお支払いが出来ます。</p>
      <div><img
          width="190"
          src={require("../images/paypay_2_rgb.png")}
          alt="PayPay支払いに対応"
        /></div>
    </div>
  );
};

export default PayPay;
