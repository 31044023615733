import React from "react";

export const ClosedDaysLabel = () => {
  return (
    <p style={{ marginBottom: 32 }}>
      <label
        style={{
          background: "#ed8e00",
          color: "#fff",
          padding: "4px 8px",
          borderRadius: 4,
        }}
      >
        休診日
      </label>
      <span style={{ marginLeft: 12 }}>木・日・祝祭日</span>
    </p>
  );
};
