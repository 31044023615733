import React, { useRef, useEffect } from "react";

const LineLink = () => {
  const wrapper = useRef(null);
  useEffect(() => {
    const win = window as any;
    if (win.LineIt) {
      win.LineIt.loadButton();
    } else {
      const script = document.createElement("script");
      script.src =
        "https://d.line-scdn.net/r/web/social-plugin/js/thirdparty/loader.min.js";
      script.onload = () => {
        win.LineIt.loadButton();
      };
      (wrapper.current as any).appendChild(script);
    }
  }, []);
  return (
    <div ref={wrapper}>
      <div
        className="line-it-button"
        data-lang="ja"
        data-type="friend"
        data-lineid="@421wuqzc"
        style={{ display: "none" }}
      />
    </div>
  );
};

export default LineLink;
